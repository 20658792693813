import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.css'

export default function init() {
  GLightbox({
    loop: true,
    autoplayVideos: false,
  })
  // @ts-ignore
  window.lightbox = GLightbox()
  // @ts-ignore
  window.GLightbox = GLightbox
}
