import Alpine from 'alpinejs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import collapse from '@alpinejs/collapse';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import intersect from '@alpinejs/intersect';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import morph from '@alpinejs/morph';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import focus from '@alpinejs/focus';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import sticky from 'alpinejs-sticky';

import AsyncAlpine from 'async-alpine';

// Start Alpine

const alpinejs = {
    setup() {
        window.Alpine = Alpine;

        Alpine.plugin(collapse);
        Alpine.plugin(intersect);
        Alpine.plugin(AsyncAlpine);
        // Alpine.plugin(morph);
        // Alpine.plugin(focus);
        // Alpine.plugin(sticky);
        return Alpine;
    },
};

export default alpinejs;
